<template>
  <div>
    <v-container id="interest-table" fluid tag="section">
      <base-material-card
        icon="mdi-file-download"
        inline
        color="secondary"
        class="px-5 py-3 mb-5"
      >
        <template v-slot:heading><v-icon>mdi-file-download</v-icon></template>
        <template v-slot:after-heading
          ><h1 class="h5">
            <b>{{ $t("file_log") }}</b>
          </h1></template
        >
        <div v-if="!loading">
          <v-form
            @submit.stop.prevent="get_logs_by_page()"
            ref="form"
            v-model="valid"
            :lazy-validation="lazy"
          >
            <v-row justify="center" class="mt-6">
              <v-text-field
                class="mx-3"
                dense
                outlined
                :label="$t('name') + '/' + $t('email')"
                v-model="name_filter"
                style="max-width: 35%"
              ></v-text-field>
              <v-btn class="mx-3 secondary" type="submit">{{
                $t("apply")
              }}</v-btn>
            </v-row>
          </v-form>
          <v-simple-table data-test="ViewsPages:FileLog:TableDownload">
            <thead>
              <tr>
                <th>{{ $t("name") }}</th>
                <th>{{ $t("email") }}</th>
                <th>{{ $t("file_name") }}</th>
                <th>{{ $t("date") }}</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(l, index) in logs" :key="index">
                <td>{{ gs.show_name(l.user) }}</td>
                <td>{{ l.user.email }}</td>
                <td>{{ l.fileName }}</td>
                <td>{{ gs.convert_date(l.timestamp, false) }}</td>
              </tr>
            </tbody>
          </v-simple-table>
          <v-pagination
            color="secondary"
            @input="changePage"
            :length="total_pages"
            v-model="page"
          >
          </v-pagination>
        </div>
        <v-container v-else>
          <v-progress-circular
            style="margin-left: 50%"
            indeterminate
            size="70"
            color="primary"
          ></v-progress-circular>
        </v-container>
      </base-material-card>
    </v-container>
  </div>
</template>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import moment from "moment";

export default {
  name: "FileDownloadLog",

  components: {},

  data: () => ({
    gs: new GeneralServices(),
    apiService: new ApiService(),
    loading: false,
    page: 1,
    total_pages: 1,
    name_filter: "",
    valid: true,
    lazy: false,
    logs: [],
  }),
  async created() {
    await this.get_logs_by_page();
  },
  computed: {},
  methods: {
    show_name: function (log) {
      if (log.User.Name === null) {
        return "";
      } else {
        return log.User.Name.concat(" ").concat(log.User.LastName);
      }
    },
    async get_logs_by_page() {
      this.loading = true;
      var page = this.page;
      if (this.$route.query.page) {
        page = parseInt(this.$route.query.page);
      }
      var params = { Page: page };
      if (this.name_filter) {
        params.NameFilter = this.name_filter;
      }
      if (this.orderBy != null) {
        params.Order = this.orderBy;
        params.OrderMode = this.orderByMode;
      }
      await this.apiService
        .getRequest("filedownloadlog/filter", params)
        .then((resp) => {
          var json = resp.content;
          this.page = json.currentPage;
          this.total_pages = Math.round(json.total / json.perPage);
          this.logs = json.items;
        })
        .catch((error) => {});
      this.loading = false;
    },
    async changePage() {
      this.$router.push({
        path: "/file-logs",
        query: { page: this.page },
      });
      await this.get_logs_by_page();
    },
  },
};
</script>
